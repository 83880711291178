import formatters from "../../utils/formatters"

export default formData => {
  let query = `mutation{\n  triggerActiveCampaignAutomations(\n`
  Object.keys(formData).forEach(key => {
    const value = formData[key]
    if (Array.isArray(value)) {
      const fieldNameString = key.replace(/_/g, ` `)
      const formattedName = formatters.titleCase(fieldNameString)
      query += `    message:`
      query += `"${formattedName}: ${value.join(`, `)}"\n`
    } else {
      query += `    ${key}:`
      query += `"${value}"\n`
    }
  })
  query += `  ) {\n    error\n    result\n  }\n}`
  return query
}
