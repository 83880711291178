
// An object full of lil formattin' helpers.
// These guys are intended to be as DRY as possible, though some are
// simply aliases serving to promote semantics (ex: sentenceCase()
// is a pure call to capitalize(); they're functionally equivalent,
// but sentenceCase() provides clarity by implying the context in
// which its used.
// Note: If a formatter can't be applied for whatever reason, it'll
// just return the value that was passed to it (as opposed to throwing
// an error, or potentially breaking a build.)

const f = {

  // ex: 299792458 -> 299,792,458
  number: num => {
    try {
      const number = num.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')
      return number.split(".")[1] > 0
        ? `${number}`
        : `${number.split(".")[0]}`
    } catch { return `—` }
  },

  // ex: 1234.5678 -> 1234.57
  round: (num, places=2) => {
    try {
      return Number(Math.round(num + `e${places}`) + `e-${places}`)
    } catch { return num }
  },

  // ex: 2495.95 -> $2,495.95
  currency: num => {
    try {
      return `$${f.number(num)}`
    } catch { return num }
  },

  // ex: canine -> Canine
  capitalize: str => {
    try {
      return str[0].toUpperCase() + str.substring(1)
    } catch { return str }
  },

  // ex: who's that lady? -> Who's that lady?
  sentenceCase: str => f.capitalize(str),

  // ex: ["spicy", "medium", "so-so"] -> Spicy, Medium, So-So
  capitalizedList: str => {
    try {
      return str.split(", ").map(item =>
        f.titleCase(item)
      ).join(", ")
    } catch { return str }
  },

  // ex: professor puppy-dog -> Professor Puppy-Dog
  titleCase: str => {
    try {
      let res = str.split(" ").map(word =>
        f.capitalize(word)
      ).join(" ")
      return !res.includes("-") ? res : res.split("-").map(frag =>
        f.capitalize(frag)
      ).join("-")
    } catch { return str }
  },

}

export default f
