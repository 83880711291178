import { prettifyPhoneNumber } from "../../utils/phoneNumbers"

const rules = {
  date: value => value.toString(),
  phone: value => prettifyPhoneNumber(value),
}

export default formData => {
  let normalized = {}
  Object.keys(formData).forEach(key => {
    const value = formData[key]
    if (rules.hasOwnProperty(key)) {
      normalized[key] = rules[key](value)
    } else {
      normalized[key] = value
    }
  })
  return normalized
}
